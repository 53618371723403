import { DOMAttributes, useContext, useEffect, useRef, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import classNames from "classnames";
import times from "lodash/times";
import find from "lodash/find";
import createPersistedState from "use-persisted-state";
import Modal from "react-modal";

import { useRouter } from "next/router";
import CentraProductImage from "../components/CentraProductImage";
import { useCentraToken, useEventListener } from "../lib/utils";
import { centraGet, centraPut } from "../lib/centra-api";
import { StoryContext } from "../pages/[...slug]";
import * as ga from "../lib/ga";
import Link from "./Link";
import NewsletterForm from "./NewsletterForm";

function leftPad(n: number) {
  return n < 10 ? `0${n}` : `${n}`;
}

type Props = {
  isCheckout?: boolean;
};

const useClosedNoticesState = createPersistedState("closedNotices");

export default function Header({ isCheckout }: Props) {
  const [token, setToken] = useCentraToken();
  const context = useContext(StoryContext);
  const router = useRouter();

  const { mutate, cache } = useSWRConfig();

  const { data, mutate: mutateSelection } = useSWR(
    "https://monokel.centra.com/api/checkout/selection",
    (uri) => centraGet(uri, token)
  );

  const [showMenu, setShowMenu] = useState(false);
  const [menuFocused, setMenuFocused] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [closedNotices, setClosedNotices] = useClosedNoticesState<string[]>([]);
  const [isClient, setIsClient] = useState(false);
  const [countryPopupOpen, setCountryPopupOpen] = useState(false);
  const [newletterSignUp, setNewsletterSignUp] = useState("");

  useEffect(() => {
    setIsClient(true);
  });

  // redirect to JP site if Centra localized Japan
  useEffect(() => {
    if (data?.location.country === "JP") {
      window.location.href = "https://monokel-eyewear.jp";
    }
  }, [data]);

  const headerRef = useRef<HTMLDivElement>();
  const headerAnimationRef = useRef<{ prevHeight: number }>({ prevHeight: 0 });
  const backgroundRef = useRef<HTMLDivElement>();
  const cartRef = useRef<HTMLDivElement>();

  const bagItemsCount = leftPad(
    data?.selection.items.reduce((count, item) => count + item.quantity, 0) || 0
  );
  const selectedCountry = data?.countries.find(
    (c) => c.country === data.location.country
  );

  const media_query = "screen and (max-width: 939px)";
  const HOVER_DELAY = 300;
  const hoverRef = useRef<HTMLElement>();

  const YEAR = new Date().getFullYear();

  useEffect(() => {
    if (data) {
      for (const [key] of cache as Map<string, any>) {
        if (
          key.startsWith("https://monokel.centra.com/api/checkout/products")
        ) {
          mutate(key, centraGet(key, token));
        }
      }
    }
  }, [data?.selection.currency]);

  useEffect(() => {
    if (data?.token) {
      setToken(data.token);
    }
  }, [data?.token]);

  useEffect(() => {
    if (showMenu) {
      setShowCart(false);
    }
  }, [showMenu]);

  useEffect(() => {
    if (showCart) {
      setShowMenu(false);
    }
  }, [showCart]);

  useEffect(() => {
    headerRef.current?.classList.toggle("is-showingCart", showCart);

    if (!headerAnimationRef.current.prevHeight) {
      return;
    }

    const start = `${headerAnimationRef.current.prevHeight}px`;
    const finish = `${headerRef.current.offsetHeight}px`;

    backgroundRef.current
      .animate(
        { height: [start, finish] },
        { easing: "ease-in-out", duration: 500 }
      )
      .finished.then(() => {
        headerAnimationRef.current.prevHeight = 0;
      });
  }, [showMenu, showCart]);

  useEffect(() => {
    if (location.search && location.search.includes("country")) {
      const customCountry = location.search.split("=")[1].toUpperCase();
      setCustomerCountry(customCountry);
    }
  }, []);

  const { fallback } = useSWRConfig();
  const modelArray = find(context.dataSources, "model")?.model || [];
  const models = new Map<string, Set<string>>();

  for (const value of Object.values(fallback)) {
    const model = value.product.model;
    let frames: Set<string>;

    if (models.has(model)) {
      frames = models.get(model);
    } else {
      frames = new Set();
    }

    frames.add(value.product.frame_color);
    models.set(model, frames);
  }

  const mouseEvents: DOMAttributes<HTMLDivElement> = {
    onMouseEnter(event) {
      const matched = window.matchMedia(media_query).matches;
      if (matched) {
        return;
      }

      const target = event.currentTarget;
      hoverRef.current = target;

      setTimeout(() => {
        if (!showMenu && hoverRef.current === target) {
          headerAnimationRef.current.prevHeight =
            headerRef.current.clientHeight;
          setShowMenu(true);
        }
      }, HOVER_DELAY);
    },
    onMouseLeave(event) {
      const matched = window.matchMedia(media_query).matches;

      if (matched) {
        return;
      }

      const target = event.currentTarget;
      hoverRef.current = target;

      setTimeout(() => {
        if (showMenu && hoverRef.current === target) {
          headerAnimationRef.current.prevHeight =
            headerRef.current.clientHeight;
          setShowMenu(false);
        }
      }, HOVER_DELAY);
    },
    onClick(event) {
      const matched = window.matchMedia(media_query).matches;

      if (!matched) {
        return;
      }

      const target = event.currentTarget;
      hoverRef.current = target;

      if (target.classList.contains("Header-block--toggle")) {
        setMenuFocused(true);
        setShowMenu(!showMenu);
      } else if (target.classList.contains("Header-block")) {
        const elems = document.querySelectorAll(".Header-block");

        if (
          target.classList.contains("Header-block--store") &&
          target.classList.contains("is-selected")
        ) {
          setMenuFocused(false);
          setShowMenu(false);

          [].forEach.call(elems, function (el) {
            el.classList.remove("is-selected");
          });
        } else {
          [].forEach.call(elems, function (el) {
            el.classList.remove("is-selected");
          });

          target.classList.toggle("is-selected");
          setMenuFocused(false);
        }
      }
    },
  };

  async function setItemQuantity(item, quantity) {
    setIsAdjusting(true);

    const res = await fetch(
      `https://monokel.centra.com/api/checkout/lines/${item.line}`,
      {
        method: quantity > item.quantity ? "POST" : "DELETE",
        headers: {
          accept: `*/*; api-token=${token || "none"}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          quantity: Math.abs(quantity - item.quantity),
        }),
      }
    );

    setIsAdjusting(false);
    const data = await res.json();
    mutateSelection(data, false);
  }

  async function setCustomerCountry(country: string) {
    if (country === "JP") {
      window.location.href = "https://monokel-eyewear.jp";
      return;
    }
    window.CentraCheckout?.suspend();
    setIsAdjusting(true);
    const data = await centraPut(
      `https://monokel.centra.com/api/checkout/countries/${country}`,
      token
    );

    mutateSelection(data, false);
    window.CentraCheckout?.resume();
    setIsAdjusting(false);

    setTimeout(() => {
      setCountryPopupOpen(false);
    }, 1000);
  }

  useEventListener(
    "monokel.showcart",
    () => {
      headerAnimationRef.current.prevHeight = headerRef.current.clientHeight;
      setShowCart(true);
    },
    []
  );

  const requiredIngridKeys = ["zipCode"];
  const ingridFieldsAreFilledOut = requiredIngridKeys.every(
    (k) => data?.selection.shippingAddress[k]
  );

  const header = (
    <nav
      className={classNames("Header", {
        "Header--top": isCheckout,
        "is-open": showMenu,
        "is-focused": menuFocused,
      })}
      onMouseLeave={(event) => {
        const matched = window.matchMedia(media_query).matches;
        if (matched) {
          return;
        }

        const target = event.currentTarget;
        hoverRef.current = target;

        setTimeout(() => {
          if ((showMenu || showCart) && hoverRef.current === target) {
            headerAnimationRef.current.prevHeight =
              headerRef?.current?.clientHeight;
            setShowMenu(false);
            setShowCart(false);
          }
        }, HOVER_DELAY);
      }}
      ref={headerRef}
    >
      <div className="Header-background" ref={backgroundRef} />

      <div className="Header-block Header-block--title" {...mouseEvents}>
        <div className="Header-content">
          Copyright © {YEAR} Monokel Eyewear All Rights Reserved
        </div>

        <Link className="Header-title" href="/">
          <span>Monokel</span> <span>Eyewear</span>
        </Link>
      </div>

      <div className="Header-block Header-block--store" {...mouseEvents}>
        <ul className="Header-list">
          {modelArray?.map((m) => {
            const model = models.get(m.value);
            const query = new URLSearchParams();

            query.set("model", m.value);
            return (
              <li key={m.value}>
                <Link href={"/store?" + query.toString()}>
                  {m.name} (
                  {Array.from(model)
                    .map((f) => f?.replace("ECO", "").trim())
                    .join(", ")}
                  )
                </Link>
              </li>
            );
          })}
        </ul>

        <Link href="/store">
          Store<p>View All</p>
        </Link>
      </div>

      <div className="Header-block Header-block--projects" {...mouseEvents}>
        <ul className="Header-list">
          {context.mainMenu?.content.projects_links?.map((l) => (
            <li key={l._uid}>
              <Link href={l.link.cached_url}>{l.text}</Link>
            </li>
          ))}
        </ul>

        <Link href="/projects">
          Projects<p>View All</p>
        </Link>
      </div>

      <div className="Header-block Header-block--about" {...mouseEvents}>
        <div className="Header-content">
          {context.mainMenu?.content.about_text}
        </div>
        <div>About</div>
      </div>

      <div className="Header-block Header-block--location" {...mouseEvents}>
        <div className="Header-content">
          <button
            className="Header-countryButton"
            onClick={() => setCountryPopupOpen(true)}
          >
            Click to change your country
          </button>
        </div>

        <div className="Header-blockHeading" style={{ whiteSpace: "nowrap" }}>
          {data?.location.name}
          <sup>{data?.selection.currency}</sup>
        </div>
      </div>

      <div className="Header-block Header-block--support" {...mouseEvents}>
        <div className="Header-content Header-content--top">
          <a
            href="https://www.instagram.com/monokeleyewear/"
            target="_blank"
            rel="noreferrer"
          >
            @monokeleyewear
          </a>
        </div>

        <ul className="Header-list">
          {context.mainMenu?.content.support_links?.map((l) => (
            <li key={l._uid}>
              <Link href={l.link.cached_url}>{l.text}</Link>
            </li>
          ))}
        </ul>

        <div>Support</div>
      </div>

      <div
        className="Header-block Header-block--bag"
        onMouseEnter={(event) => {
          const matched = window.matchMedia(media_query).matches;
          if (matched) {
            return;
          }

          if (isCheckout) {
            return;
          }

          const target = event.currentTarget;
          hoverRef.current = target;

          setTimeout(() => {
            if (!showCart && hoverRef.current === target) {
              headerAnimationRef.current.prevHeight =
                headerRef?.current?.clientHeight;
              setShowCart(true);
            }
          }, HOVER_DELAY);
        }}
      >
        <Link
          className="Header-blockHeading"
          href="/checkout"
          onClick={() => {
            const items = data?.selection.items.map((item) => {
              return {
                item_id: item.product.sku,
                item_name: item.product.name,
                affiliation: item.product.brandName,
                item_collection: item.product.collectionName,
                item_variant: item.product.variantName,
                currency: data?.selection.currency,
                price: item.product.priceAsNumber,
                quantity: item.quantity,
              };
            });

            ga.event({
              action: "begin_checkout",
              params: {
                "x-fb-event_id": "begin_checkout",
                items: items,
              },
            });
          }}
        >
          Bag <sup className="Header-bagAmount">{bagItemsCount}</sup>
        </Link>
      </div>

      <div className="Header-block Header-block--toggle" {...mouseEvents}>
        <button></button>
      </div>

      {!isCheckout ? (
        <div
          className="Header-cartWrapper"
          onMouseEnter={(event) => {
            hoverRef.current = event.currentTarget;
          }}
          onMouseLeave={(event) => {
            const matched = window.matchMedia(media_query).matches;
            if (matched) {
              return;
            }

            const target = event.currentTarget;
            hoverRef.current = target;

            setTimeout(() => {
              if (showCart && hoverRef.current === target) {
                headerAnimationRef.current.prevHeight =
                  headerRef.current.clientHeight;
                setShowCart(false);
              }
            }, HOVER_DELAY);
          }}
          ref={cartRef}
        >
          <div className="Header-cart">
            <ul className="Cart-items">
              {data?.selection.items.map((item) => (
                <li
                  className={classNames("Cart-item", {
                    "is-disabled": isAdjusting,
                  })}
                  key={item.line}
                >
                  {item.product.media["1000x0"]?.[0] ? (
                    <Link
                      className="Cart-itemImage"
                      href={"/" + item.product.uri}
                    >
                      <CentraProductImage
                        src={item.product.media["1000x0"][0]}
                        sizes="(min-width: 1024px) 25vw, 50vw"
                      />
                    </Link>
                  ) : null}

                  <div className="Cart-itemText">
                    <h4 className="Cart-itemTextRow">
                      <Link href={"/" + item.product.uri}>
                        {item.product.name}
                      </Link>
                      , {item.priceEach}
                    </h4>

                    <div className="Cart-itemTextRow">
                      <span>Quantity</span>

                      <div className="Cart-itemQtyButtons">
                        {times(10, (i) => (
                          <button
                            className={classNames("Cart-itemQtyButton", {
                              "is-active": item.quantity === i + 1,
                            })}
                            key={i}
                            onClick={() => setItemQuantity(item, i + 1)}
                          >
                            {leftPad(i + 1)}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="Cart-itemTextRow">
                      <button
                        className="Cart-itemRemove"
                        onClick={() => {
                          ga.event({
                            action: "remove_from_cart",
                            params: {
                              "x-fb-event_id": "remove_from_cart",
                              items: [
                                {
                                  item_id: item.product.sku,
                                  item_name: item.product.name,
                                  affiliation: item.product.brandName,
                                  item_collection: item.product.collectionName,
                                  item_variant: item.product.variantName,
                                  currency: data?.selection.currency,
                                  quantity: item.quantity,
                                  price: item.product.priceAsNumber,
                                },
                              ],
                            },
                          });
                          setItemQuantity(item, 0);
                        }}
                      >
                        Remove
                      </button>
                    </div>

                    <div className="Cart-itemTextRow Cart-itemTextRow--bottom">
                      Subtotal {item.totalPrice}
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {!data?.selection.items.length ? (
              <div className="Cart-footer Cart-footer--header">
                <div className="Cart-checkoutLink">
                  <div>No items in your bag</div>
                  <div>&nbsp;</div>
                </div>
              </div>
            ) : null}

            {data?.selection.items.length ? (
              <div className="Cart-footer Cart-footer--header">
                <div className="Cart-footerInfo">
                  <div className="Cart-footerInfoText">Shipping</div>
                  {ingridFieldsAreFilledOut ? (
                    <div className="Cart-footerInfoValue">
                      {data?.selection.totals.shippingPriceAsNumber
                        ? data?.selection.totals.shippingPrice
                        : "Free"}
                    </div>
                  ) : (
                    <div className="Cart-footerInfoValue">
                      Calculated at checkout
                    </div>
                  )}
                </div>

                <Link
                  className="Cart-checkoutLink"
                  href="/checkout"
                  onClick={() => {
                    const items = data?.selection.items.map((item) => {
                      return {
                        item_id: item.product.sku,
                        item_name: item.product.name,
                        affiliation: item.product.brandName,
                        item_collection: item.product.collectionName,
                        item_variant: item.product.variantName,
                        currency: data.selection.currency,
                        quantity: item.quantity,
                        price: item.product.priceAsNumber,
                      };
                    });

                    ga.event({
                      action: "begin_checkout",
                      params: {
                        "x-fb-event_id": "begin_checkout",
                        items: items,
                      },
                    });
                  }}
                >
                  <div>Total, {data?.selection.totals.itemsTotalPrice}</div>
                  <div>Proceed to checkout</div>
                </Link>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </nav>
  );

  return (
    <>
      {isClient ? (
        <div
          className={classNames("Header-notices", {
            "Header-notices--top": isCheckout,
          })}
        >
          {context.mainMenu?.content.notices?.map((n) => {
            if (n.is_newsletter) {
              return (
                <div
                  key={n._uid}
                  className={classNames(
                    "Header-notice Header-notice--grey Header-notice--no-padding-left",
                    {
                      "is-hidden":
                        !context.preview &&
                        closedNotices?.includes("newsletter"),
                    }
                  )}
                >
                  <div className="Header-notice-newsletterForm">
                    {newletterSignUp === "success" ? (
                      <div className="Header-notice-newsletterSuccess">
                        Thank you. We will be in touch.
                      </div>
                    ) : (
                      <NewsletterForm
                        placeholder={n.heading || "Subscribe to our newsletter"}
                        signUpSuccessfull={() => setNewsletterSignUp("success")}
                        signUpError={() => setNewsletterSignUp("error")}
                      />
                    )}
                  </div>

                  <button
                    className="Header-noticeButton"
                    type="button"
                    onClick={() =>
                      setClosedNotices(closedNotices.concat("newsletter"))
                    }
                  >
                    Close
                  </button>
                </div>
              );
            }

            return (
              <div
                className={classNames("Header-notice Header-notice--brown", {
                  "is-hidden":
                    !context.preview && closedNotices?.includes(n._uid),
                })}
                key={n._uid}
              >
                <h3 className="Header-noticeHeading">{n.heading}</h3>

                <div className="Header-noticeText1">
                  <div
                    className="u-richText"
                    dangerouslySetInnerHTML={{
                      __html: n.text_1.content,
                    }}
                  />
                </div>

                <div className="Header-noticeText2">
                  <div
                    className="u-richText"
                    dangerouslySetInnerHTML={{
                      __html: n.text_2.content,
                    }}
                  />
                </div>

                <button
                  className="Header-noticeButton"
                  type="button"
                  onClick={() => setClosedNotices(closedNotices.concat(n._uid))}
                >
                  Close
                </button>
              </div>
            );
          })}

          {/* <div
            className={classNames("Header-notice", {
              "is-hidden":
                !context.preview && closedNotices?.includes("cookies"),
            })}
          >
            <h3 className="Header-noticeHeading">Cookies</h3>

            <div className="Header-noticeText">
              This site uses cookies. By continuing to use this site, you agree
              to our terms of service.
            </div>

            <button
              className="Header-noticeButton"
              type="button"
              onClick={() => setClosedNotices(closedNotices.concat("cookies"))}
            >
              Close
            </button>
          </div> */}
        </div>
      ) : null}

      {header}

      <Modal
        bodyOpenClassName="is-showingPopup"
        className="Popup-wrapper"
        isOpen={countryPopupOpen}
        onRequestClose={() => setCountryPopupOpen(false)}
        style={{
          overlay: {
            backgroundColor: "var(--color-black-opacity)",
            overflow: "auto",
            zIndex: 105,
          },
        }}
      >
        <div className="Popup-modal Popup-modal--locale">
          <button
            className="Popup-close"
            onClick={() => setCountryPopupOpen(false)}
          >
            <span>Close</span>
          </button>

          <div className="Popup-formWrapper">
            <label
              className={classNames("Checkout-label Checkout-label--full", {
                "is-disabled": isAdjusting,
              })}
            >
              <span className="Checkout-labelText">
                Shipping country <abbr title="Required">*</abbr>
              </span>

              <div className="Checkout-fauxSelect">
                {selectedCountry?.name} &darr;
              </div>

              <select
                className="Checkout-select"
                name="country"
                disabled={isAdjusting}
                onChange={(event) =>
                  setCustomerCountry(event.currentTarget.value)
                }
                value={data?.location.country}
              >
                {data?.countries.map((c) => (
                  <option value={c.country} key={c.country}>
                    {c.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </Modal>
    </>
  );
}
