import { fetchRetry, fetchWithCache } from "./utils";

export async function centraGet(url: string, token?: string) {
  const res = await fetchRetry(url, {
    headers: {
      accept: `*/*; api-token=${token}`,
    },
  });

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`${res.status}`);
  }
}

export async function centraGetWithCache(url: string, token?: string) {
  return await fetchWithCache(url, {
    headers: {
      accept: `*/*; api-token=${token}`,
    },
  });
}

export async function centraPost(
  url: string,
  token?: string,
  body?: any,
  signal?: AbortSignal
) {
  const res = await fetchRetry(url, {
    method: "POST",
    signal,
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });

  const resultJson = await res.json();

  if (res.ok) {
    return resultJson;
  } else {
    throw new Error(JSON.stringify(resultJson));
  }
}

export async function centraPostWithCache(
  url: string,
  token?: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "POST",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export async function centraPostWithAuthAndCache(
  url: string,
  token?: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "POST",
    headers: {
      accept: `*/*; api-token=${token}`,
      authorization: `Bearer ${process.env.CENTRA_SHARED_SECRET}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
}

export async function centraPut(url: string, token: string, body?: any) {
  const res = await fetchRetry(url, {
    method: "PUT",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    return await res.json();
  } else {
    throw new Error(`${res.status}`);
  }
}

export async function centraPutWithCache(
  url: string,
  token: string,
  body?: any
) {
  return await fetchWithCache(url, {
    method: "PUT",
    headers: {
      accept: `*/*; api-token=${token}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  });
}
