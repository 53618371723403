import classNames from "classnames";
import { useState } from "react";
import FormField from "./BlockFormField";

type Props = {
  blok?: {
    form_name: string;
    heading: string;
    description: string;
    fields: {
      component: string;
      name: string;
      type: string;
      label: string;
      placeholder: string;
    }[];
    receiver_email: string;
    width?: string;
  };
};

export default function FormBlock({ blok }: React.PropsWithChildren<Props>) {
  const width = blok.width;

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const fields = blok.fields.map((f) => {
    if (f.component === "form-input") {
      return f;
    }
  });

  const submitForm = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setIsSending(true);

    const formData = {};

    fields.map((f) => {
      formData[f.name] = e.target[f.name].value;
    });

    const res = await fetch("/api/submit-to-email", {
      body: JSON.stringify({
        toEmail: blok.receiver_email,
        formName: blok.form_name,
        formData: formData,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });

    const result = await res.json();

    if (!result.ErrorCode) {
      setSuccess(true);
    } else {
      setError(true);
      console.log(result.message);
    }
  };

  let submitButtonLabel = "Send";

  if (success) {
    submitButtonLabel = "Sent";
  } else if (error) {
    submitButtonLabel = "...";
  } else if (isSending) {
    submitButtonLabel = "Sending...";
  }

  return (
    <div
      className={classNames("Grid-block", {
        [`Grid-block--${width}`]: width && width !== "regular",
      })}
    >
      <div className="Form Grid-blockContent">
        <h2 className="Form-heading">{blok.heading}</h2>
        <p className="Form-description">{blok.description}</p>

        <form className="Form-form" onSubmit={submitForm}>
          {fields?.map((field, i) => {
            return (
              <FormField
                key={field.name + i}
                component={field.component}
                label={field.label}
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
              />
            );
          })}
          <button
            disabled={isSending || error || success}
            className="Form-submit"
          >
            {submitButtonLabel}
          </button>
        </form>

        {error && (
          <div className="Form-error">
            <p>
              Uh oh. Something went wrong while submitting the form. Please try
              again later.
            </p>
          </div>
        )}
        {success && (
          <div className="Form-success">
            <p>
              Thank you for your submission! We will get back to you as soon as
              possible.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
