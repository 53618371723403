import classNames from "classnames";
import StoryblokImage from "./StoryblokImage";

type Props = {
  blok?: {
    link?: {
      cached_url: string;
      url: string;
    };
    media?: StoryblokAsset;
    text?: string;
    text_color?: string;
    width?: "regular" | "double" | "large" | "full";
  };
};

const imageSizes = {
  regular: "25vw",
  double: "50vw",
  large: "50vw",
  full: "100vw",
};

export default function MediaBlock({ blok }: React.PropsWithChildren<Props>) {
  const media = blok.media;
  const width = blok.width;

  if (!media.filename) {
    return "";
  }

  const fileType = media.filename.split(".").slice(-1)[0];
  const isVideo = fileType === "mp4";

  let getLink = "";
  if (blok.link.url) {
    getLink = blok.link.url;
  } else if (blok.link.cached_url) {
    getLink = "/" + blok.link.cached_url;
  }

  const Tag = getLink ? "a" : "div";
  const content = (
    <Tag
      className={classNames("Grid-block", {
        [`Grid-block--${width}`]: width && width !== "regular",
      })}
      {...(getLink && { href: getLink })}
    >
      {isVideo ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <div
          className={classNames("Grid-blockGraphic", {
            "Grid-blockGraphic--landscape": width === "double",
          })}
        >
          <video autoPlay loop muted>
            <source src={media.filename} type="video/mp4" />
          </video>
        </div>
      ) : (
        <StoryblokImage
          className={classNames("Grid-blockGraphic", {
            "Grid-blockGraphic--landscape": width === "double",
          })}
          asset={media}
          sizes={`(min-width: 840px) ${imageSizes[width]}, 100vw`}
        />
      )}
      {blok.text ? (
        <div
          className={classNames(
            "Grid-blockContent Grid-blockContent--overProductImage",
            {
              "is-light-text": blok.text_color === "light",
            }
          )}
        >
          {blok.text}
        </div>
      ) : null}
    </Tag>
  );

  return content;
}
