import classNames from "classnames";

type Props = {
  blok?: {
    width: string;
  };
};

export default function SpacerBlock({ blok }: React.PropsWithChildren<Props>) {
  const width = blok.width;

  return (
    <div
      className={classNames("Grid-block", {
        [`Grid-block--${width}`]: width && width !== "regular",
      })}
    >
      <div
        className={classNames("Grid-blockSpacer", {
          "Grid-blockSpacer--landscape": width === "double",
        })}
      />
    </div>
  );
}
