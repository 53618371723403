import classNames from "classnames";

type Props = {
  blok?: {
    body: {
      content: string;
    };
    width: string;
  };
};

export default function TextBlock({ blok }: React.PropsWithChildren<Props>) {
  const width = blok.width;

  return (
    <div
      className={classNames("Grid-block Grid-block--text", {
        [`Grid-block--${width}`]: width && width !== "regular",
      })}
    >
      <div className="Grid-blockContent">
        <div
          className="u-richText"
          dangerouslySetInnerHTML={{
            __html: blok.body.content,
          }}
        />
      </div>
    </div>
  );
}
