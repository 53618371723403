import { MutableRefObject, useEffect, useState } from "react";
import fetchRetryModule from "@vercel/fetch-retry";
import { useRouter } from "next/router";
import createPersistedState from "use-persisted-state";
import useSWR from "swr";

import { centraGet } from "./centra-api";

export const fetchRetry = fetchRetryModule(fetch);
const fetchCache = new Map();

export async function fetchWithCache(uri: RequestInfo, args?: RequestInit) {
  const key = JSON.stringify([uri, args]);

  if (fetchCache.has(key)) {
    return fetchCache.get(key);
  }

  const res = await fetchRetry(uri, args);

  if (res.ok) {
    const data = await res.json();
    fetchCache.set(key, data);
    setTimeout(() => fetchCache.delete(key), 3 * 60 * 1000);

    return data;
  } else {
    throw new Error(`${res.status}`);
  }
}

export function useBodyClass(className: string) {
  useEffect(() => {
    if (!className) {
      return;
    }

    const classNames = className.split(" ");
    classNames.map((c) => document.body.classList.add(c));

    return () => {
      classNames.map((c) => document.body.classList.remove(c));
    };
  }, [className]);
}

export function useMediaQuery(query: string): boolean {
  let defaultValue = false;

  if (typeof window !== "undefined") {
    const media = window.matchMedia(query);
    defaultValue = media.matches;
  }

  const [matches, setMatches] = useState(defaultValue);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    function listener() {
      setMatches(media.matches);
    }

    media.addEventListener("change", listener);

    return () => {
      media.removeEventListener("change", listener);
    };
  }, [matches, query]);

  return matches;
}

export function useScrollIntoView(
  ref: MutableRefObject<HTMLElement>,
  selector: string
) {
  const router = useRouter();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const target = ref.current.querySelector(selector);

    if (target) {
      target.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    }
  }, [ref.current, router.asPath]);
}

export function useAbortOnRouteChange() {
  if (typeof AbortController === "undefined") {
    return undefined;
  }

  const router = useRouter();
  const fetchController = new AbortController();

  useEffect(() => {
    function handleRouteChange() {
      fetchController.abort();
    }

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  return fetchController;
}

const useTokenState = createPersistedState("centraSelectionToken");

export function useCentraToken() {
  return useTokenState("");
}

export function serializeForm(form: HTMLFormElement): {
  [key: string]: string;
} {
  const data = {};

  for (const el of form.elements) {
    const element = el as HTMLInputElement;

    if (element.name && element.value) {
      data[element.name] = element.value;
    }
  }

  return data;
}

export function useEventListener(
  eventName: string,
  eventListener: (e: CustomEvent) => void,
  deps = []
) {
  useEffect(() => {
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, deps);
}

export function useProductPrice(product?: CentraProduct): CentraPricelistItem {
  const [token] = useCentraToken();

  const { data: selectionData } = useSWR(
    "https://monokel.centra.com/api/checkout/selection",
    (uri) => centraGet(uri, token)
  );

  if (product?.prices) {
    if (selectionData?.location.pricelist) {
      return product.prices[selectionData.location.pricelist];
    }
  } else if (product?.price) {
    return {
      price: product.price,
      priceAsNumber: product.priceAsNumber,
      priceBeforeDiscount: product.priceBeforeDiscount,
      priceBeforeDiscountAsNumber: product.priceBeforeDiscountAsNumber,
      showAsNew: product.showAsNew,
      showAsOnSale: product.showAsOnSale,
    };
  }
}

export function numberWithSpaces(x) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
