import ProductGridItem from "./ProductGridItem";

type Props = {
  blok: {
    cover_media?: StoryblokAsset;
    product?: {
      productIds: object;
    };
    product_picker_test?: {
      productIds: object;
    };
    width?: string;
  };
};

export default function ProductBlock({ blok }: Props) {
  const productBlock = blok.product;
  const product_test = blok.product_picker_test;
  const productIds = productBlock?.productIds;
  const coverMedia = blok?.cover_media;
  const width = blok.width;

  const productIdFallback = product_test?.productIds;

  if (!productIds?.[0] && !productIdFallback?.[0]) {
    return <></>;
  }

  return (
    <ProductGridItem
      className={width && width !== "regular" ? `Grid-block--${width}` : ""}
      coverMedia={coverMedia}
      productId={productIds[0] || productIdFallback[0]}
      forceAspectRatio
    />
  );
}
