import {
  RefAttributes,
  forwardRef,
  HTMLProps,
  useEffect,
  useState,
} from "react";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";

export type LinkProps = {
  activeClassName?: string;
} & RefAttributes<HTMLAnchorElement> &
  HTMLProps<HTMLAnchorElement> &
  NextLinkProps;

export default forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps>
>(function Link(
  {
    activeClassName = "is-active",
    as,
    children,
    className,
    href = "",
    locale,
    passHref,
    prefetch,
    replace,
    scroll,
    shallow,
    ...props
  },
  ref
): React.ReactElement {
  const router = useRouter();
  // Remove potential hash from the current path
  const asPath = router.asPath.replace(/\?.*?$/, "");
  const compareHref = href.replace(/\?.*?$/, "");
  const localeMatches = locale ? locale === router.locale : true;
  const [target, setTarget] = useState("");

  useEffect(() => {
    const hrefUrl = new URL(href, location.href);
    const differentHost = hrefUrl.hostname !== location.hostname;
    setTarget(differentHost ? "_blank" : "");
  }, [href]);

  const linkProps = {
    as,
    href,
    locale,
    passHref,
    prefetch,
    replace,
    scroll,
    shallow,
  };

  return (
    <NextLink {...linkProps}>
      <a
        className={classNames(className, {
          [activeClassName]:
            localeMatches && (asPath === compareHref || asPath === as),
        })}
        ref={ref}
        target={target}
        {...props}
      >
        {children}
      </a>
    </NextLink>
  );
});
