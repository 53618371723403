import SbEditable from "storyblok-react";
import MediaBlock from "./BlockMedia";
import PageBlock from "./BlockPage";
import ProductBlock from "./BlockProduct";
import SpacerBlock from "./BlockSpacer";
import TextBlock from "./BlockText";
import WideTextBlock from "./BlockTextWide";
import FormBlock from "./BlockForm";

const Components = {
  "image-block": MediaBlock,
  page: PageBlock,
  "product-block": ProductBlock,
  "spacer-block": SpacerBlock,
  "text-block": TextBlock,
  text_wide: WideTextBlock,
  "form-section": FormBlock,
};

export default function DynamicComponent({ blok }) {
  if (Components[blok.component]) {
    const Component = Components[blok.component];

    return (
      <SbEditable content={blok}>
        <Component blok={blok} key={blok._uid} />
      </SbEditable>
    );
  }

  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet.
    </p>
  );
}
