type Props = {
  width?: string;
  component: string;
  name: string;
  type: string;
  label?: string;
  placeholder: string;
};

export default function FormField(props: Props) {
  const width = props.width;

  if (props.type === "textarea") {
    return (
      <textarea
        className="Form-textarea"
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        rows={5}
      ></textarea>
    );
  } else {
    return (
      <input
        className="Form-input"
        id={props.name}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        autoComplete={props.name}
      />
    );
  }
}
