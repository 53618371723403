import { useEffect, useState } from "react";
import { StoryData } from "storyblok-js-client";

declare global {
  interface Window {
    StoryblokBridge: any;
  }
}

export function useStoryblok(originalStory: StoryData, preview: boolean) {
  const [story, setStory] = useState(originalStory);

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    if (window.StoryblokBridge) {
      const storyblokInstance = new window.StoryblokBridge();

      storyblokInstance.on(["change", "published"], () => location.reload());

      storyblokInstance.on("input", (event) => {
        if (event.story.id === originalStory?.id) {
          setStory(event.story);
        }
      });
    }
  }

  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback) {
    const existingScript = document.getElementById("storyblokBridge");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (preview) {
      addBridge(initEventListeners);
    }
  }, [originalStory, preview]);

  useEffect(() => {
    setStory(originalStory);
  }, [originalStory]);

  return story;
}
