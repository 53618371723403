type Props = {
  blok?: {
    column_1: {
      content: string;
    };
    column_2: {
      content: string;
    };
    column_3: {
      content: string;
    };
  };
};

export default function WideTextBlock({
  blok,
}: React.PropsWithChildren<Props>) {
  return (
    <div className="Grid-block Grid-block--wideText">
      <div
        className="Grid-content Grid-contentColumn1"
        dangerouslySetInnerHTML={{
          __html: blok?.column_1?.content,
        }}
      ></div>
      <div
        className="Grid-content Grid-contentColumn2"
        dangerouslySetInnerHTML={{
          __html: blok?.column_2?.content,
        }}
      ></div>
      <div
        className="Grid-content Grid-contentColumn3"
        dangerouslySetInnerHTML={{
          __html: blok?.column_3?.content,
        }}
      ></div>
    </div>
  );
}
