import React from "react";
import CentraProductImage from "./CentraProductImage";

interface ZoomGalleryProps {
  onClick: () => void;
  images: HTMLImageElement[];
}

const ZoomGallery = ({ onClick, images }: ZoomGalleryProps) => {
  return (
    <div className={"ZoomGallery"} onClick={onClick}>
      <div className='"ZoomGallery-wrapper"'>
        {images.map((img, i) => {
          if (img) {
            return (
              <div key={img.src + i} id={`zoom-${i}`}>
                <CentraProductImage
                  className="ZoomGallery-image"
                  src={img.src}
                  width="100%"
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ZoomGallery;
