import DynamicBlock from "./DynamicBlock";

export default function Page({ blok }) {
  return (
    <div className="Grid">
      {blok.body?.map((blok) => (
        <DynamicBlock blok={blok} key={blok._uid} />
      ))}
    </div>
  );
}
